<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <account-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountInfor.accountDateTitle1')}}</p>
          </div>
          <div v-if="noIdentity" class="identity-box">
            <img class="sf-suss-img" src="@/assets/images/account/sf-cuss2-img.png" alt="">
            <p class="tips-p">{{$t('accountInfor.identityTitle')}}</p>
            <div class="from-box-bg">
                <div class="conter-box">
                  <div class="real-name">
                    <div class="real-name-top">
                      <p class="top-title">{{$t('perfectData.informationConfirmTitle3')}}</p>                     
                    </div>  
                    <input class="input-text" :value="findUserInfo.name" disabled="true"/>
                  </div>
                  <div class="identity-card">
                        <p>{{$t('perfectData.informationConfirmTitle4')}}</p>
                        <input class="input-text" :value="findUserInfo.idNumber" disabled="true"/>
                  </div>
                  <div class="ts-box">
                    <img src="../assets/images/register/ys-icon.png" alt="">
                    <p>{{$t('perfectData.informationConfirmTitle5')}}</p>
                  </div>  
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 实名认证组件 -->
    <perfect-data-model :showModal="showModal"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import AccountNav from '@/components/AccountNav.vue';
import PerfectDataModel from '@/components/PerfectDataModel.vue';
import { getCookie } from '@/util/cookie';
import account from '@/api/account.js';
import {HEADER_USER_TOKEN} from "@/util/const";
export default {
  name: "Authentication",
  components: {
    Header,
    LeftMenu,
    AccountNav,
    PerfectDataModel
  },
  data() {
    return {
      loginState:2,
      showModal:false,
      noIdentity:false,
      findUserInfo:[],
    }
  },
  created() {
    const that = this;

    // 查询用户基本资料
    const userToken = getCookie(HEADER_USER_TOKEN);
    console.log(userToken);
    if(!userToken){
      this.$router.push({ path: '/login' })
    }else{
      account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              that.findUserInfo = res.data.value
              if(res.data.value.identityResult == 1){
                  that.noIdentity = true;
                  that.showModal = false                
                }else{
                  that.showModal = true
                }
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }
  },
  methods: {
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
